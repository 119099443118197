// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
	import.meta.hot.accept(() => {
		console.log("HMR")
	});
}

import "../css/app.css";

document.addEventListener("DOMContentLoaded", () => {
    const mainMenuItems = document.getElementById('mainMenuItems-mobile');
    const mobileMenuToggle = document.getElementById('mobile-menu-toggle');
    const mainBlockWrapper = document.getElementById('main-block-wrapper');
    const mobileMenuClose = document.getElementById('mobile-menu-close');

    console.log('mainMenuItems', mainMenuItems);
    console.log('mobileMenuToggle', mobileMenuToggle);

    if (mainMenuItems && mobileMenuToggle && mainBlockWrapper) {
        mobileMenuToggle.addEventListener('click', () => {
            console.log("mobile menu clicked");
            mainMenuItems.classList.toggle('hidden');
            mainMenuItems.classList.toggle('flex');
            mainBlockWrapper.classList.toggle('hidden');
        });
    }

    if (mobileMenuClose && mainBlockWrapper && mainMenuItems) {
        mobileMenuClose.addEventListener('click', () => {
            mainMenuItems.classList.toggle('hidden');
            mainMenuItems.classList.toggle('flex');
            mainBlockWrapper.classList.toggle('hidden');
        });
    }
})
